/*
 * @Author: lanbin1987@gmail.com
 * @Date: 2021-06-09 12:32:13
 * @Last Modified by: lanbin1987@gmail.com
 * @Last Modified time: 2021-06-10 16:52:56
 */
import Tech from './tech';
import Operate from './operate';
import Product from './product';
import Extension from './extension';
import Design from './design';

export default [
  {
    name: '技术类',
    icon: 'icon-tech',
    jobs: Tech,
  },
  {
    name: '运营类',
    icon: 'icon-operate',
    jobs: Operate,
  },
  {
    name: '产品类',
    icon: 'icon-product',
    jobs: Product,
  },
  {
    name: '交互类',
    icon: 'icon-extension',
    jobs: Extension,
  },
  {
    name: '设计类',
    icon: 'icon-design',
    jobs: Design,
  },
];
