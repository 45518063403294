export default [
  // {
  //   name: '市场推广',
  //   salary: '6-12K',
  //   workingYears: '1-3年',
  //   education: '专科',
  //   location: '长沙',
  //   res: [
  //     '负责APP推广工作，拓展开发新渠道；',
  //     '负责在线上线下寻找目标客户（公司/机构/团体/个人），邀请数字媒体相关行业创作者入驻，增进APP下载注册量；',
  //     '负责包装公司及产品介绍PPT，对目标用户进行解说；',
  //     '实时了解行业信息，保持对市场的敏锐度，与异业机构开展合作推广；',
  //     '跟进已入驻平台或已合作的客户，寻求转介绍，及时了解并处理对方需求，维护客户关系。',
  //   ],
  //   req: [
  //     '两年以上APP推广经验，有传媒、影视制作行业从业经验优先考虑；',
  //     '沟通能力强，思维活跃，经常活跃于主流互联网平台；',
  //     '具有开拓进取精神，不断发掘新思路新想法；',
  //     '有一定的创作者资源者（摄影、剪辑、特效等相关客户资源）优先。',
  //   ],
  //   email: '493354593@qq.com',
  // },
  {
    name: '交互设计师',
    salary: '8-16K',
    workingYears: '1-3年',
    education: '专科',
    location: '长沙',
    res: [
      '参与产品线的需求设计过程，负责产品的用户体验设计和优化工作；',
      '在设计过程中，负责产品的信息架构设计、导航、界面交互、操作流程等交互设计工作，并输出和更新交互设计规范，对接视觉设计师完成相关工作；',
      '在设计交付后，负责跟进后期开发落地效果，体验测试和用户使用行为分析等体验闭环和用户调研回访的工作；',
      '长期跟进产品线的体验目标达成情况，并结合用户调研和体验数据分析工作，推进产品的体验改进和优化。',
    ],
    req: [
      '1从事交互设计工作1-3年，学习能力强、逻辑思维敏捷，有较强的推动力，能推动用户体验研究结果落地到产品，能够熟练产出交互原型，并且思路清晰的表达和展示设计方案；',
      '踏实勤奋，积极上进，并能承受较高强度的工作压力；',
      '熟悉人机交互、心理学、社会学、计算机网络的优先。',
    ],
    email: 'lhoo@vip.qq.com',
  },
];
