export default [
  
  {
    name: 'UI设计师',
    salary: '9-14K',
    workingYears: '1-3年',
    education: '不限',
    location: '长沙',
    res: ['负责幕童产品（积幕APP、幕童有课）移动端、WEB端的设计工作。'],
    req: [
      '1年以上UI设计经验；',
      '态度严谨，工作认真负责，有团队精神，沟通能力配合工作能力强，对UI视觉设计有热情；',
      '平面设计功底扎实，良好的设计技巧，对结成、线条、色彩等的把控；',
      '视野广阔，有一定高度的审美水平；',
      '有灵活的与时俱进的设计思维，丰富想象力和创新能力，对自己作品有高度要求。',
    ],
    email: 'me@wkun.com',
  },
];
