<template>
  <div class="page join-us">
    <Header></Header>
    <div class="top-img">
      <div class="text">
        <h1>WELCOME TO MOVTILE</h1>
        <p>一起扬帆起航</p>
        <p>生活不止眼前的“够呛”，还有不可估量的远方</p>
      </div>
    </div>
    <div class="job-container">
      <div class="job-nav">
        <div
          class="nav-item"
          :class="{ active: index == selectedSort }"
          v-for="(item, index) in JobData"
          @click="selectedSort = index"
          :key="index"
          :style="{ backgroundImage: `url(${iconImage(item.icon)})` }"
        >
          {{ item.name }}
        </div>
      </div>

      <div class="job-list">
        <div class="job-card" v-for="(job, index) in currentJobs" :key="index">
          <section class="name">
            {{ job.name }}
            <span class="salary">{{ job.salary }}</span>
          </section>
          <section class="other">
            <span>{{ job.workingYears }}</span>
            <span>{{ job.education }}</span>
            <span class="location">{{ job.location }}</span>
          </section>
          <section>
            <h4>岗位职责:</h4>
            <ul>
              <li v-for="(res, index) in job.res" :key="index">{{ res }}</li>
            </ul>
          </section>
          <section>
            <h4>岗位要求:</h4>
            <ul>
              <li v-for="(req, index) in job.req" :key="index">{{ req }}</li>
            </ul>
          </section>
          <section class="email">简历投递: {{ job.email }}</section>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import Header from '@/components/Header';
  import Footer from '@/components/Footer';
  import JobData from '@/data/job';

  export default {
    data() {
      return {
        selectedSort: '',
        currentJobs: [],
        JobData,
      };
    },
    components: { Header,Footer },
    watch: {
      selectedSort() {
        this.currentJobs = this.JobData[this.selectedSort].jobs;
      },
    },
    computed: {},
    mounted() {
      this.selectedSort = 0;
    },
    methods: {
      iconImage(name) {
        return require(`../assets/images/${name}.png`);
      },
    },
  };
</script>

<style lang="less" scoped>
  .join-us {
    color: #222222;
    margin: 0 auto;
  }

  .top-img{
    background: url(https://ketang-pub.movtile.com/res/official/job-top.jpg) no-repeat;
    background-size: cover;
    height: 700px;
  }

  .job-container {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
    text-align: left;

    .job-nav {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;

      .nav-item {
        width: 300px;
        height: 120px;
        background-color: white;
        display: flex;
        align-items: center;
        padding: 0 25px;
        cursor: pointer;
        transition: all 0.2s;
        border-bottom: 3px solid transparent;
        font-weight: 800;
        background-position: right 25px center;
        background-repeat: no-repeat;
        background-size: 40px;
        font-weight: 800;

        &.active,
        &:hover {
          border-bottom: 3px solid #2247ff;
          color: #2247ff;
          box-shadow: 0px 10px 18px 2px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .job-list {
    width: 100%;
    margin-top: 50px;

    .job-card {
      padding: 44px;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      background: url(https://ketang-pub.movtile.com/res/official/card-logo-bg.png) 100% -8%
        no-repeat white;
      background-size: 500px 290px;

      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 10px 18px 2px rgba(0, 0, 0, 0.1);
      }

      section {
        margin-bottom: 10px;
        font-size: 14px;

        h4{
          font-weight: 800;
        }

        ul {
          list-style: decimal;
        }
      }

      .name {
        font-size: 24px;
        font-weight: 800;
        font-weight: 800;
        .salary {
          margin-left: 15px;
          color: #2247ff;
        }
      }

      .other {
        margin-bottom: 30px;
        span {
          margin-right: 15px;

          &.location {
            background: url(https://ketang-pub.movtile.com/res/official/card-position.png) left
              center no-repeat;
            background-size: 13px;
            padding-left: 15px;
          }
        }
      }

      .email {
        font-size: 14px;
        font-weight: 800;
      }
    }
  }

  @media screen and (max-width: 1536px) {
    .top-img {
      .text {
        h1 {
          font-size: 70px;
        }
      }
      height: 480px;
    }

    .job-container {
      max-width: 1200px;
      .job-nav {
        .nav-item {
          width: 220px;
          height: 100px;
          background-size: 30px;
        }
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .top-img {
      .text {
        h1 {
          font-size: 40px;
          margin-top: 40px;
        }
      }
    }

    .job-container {
      max-width: 800px;
      .job-nav {
        .nav-item {
          width: 150px;
          height: 80px;
          background-size: 30px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .top-img {
      height: 120px;
      .text {
        h1,
        p {
          display: none;
        }
      }
    }

    .job-container {
      max-width: 95%;
      .job-nav {
        margin-top: 20px;

        .nav-item {
          width: 19%;
          height: 60px;
          background-size: 30px;
          background-blend-mode: screen;
          padding: 0;
          justify-content: center;
        }
      }

      .job-list {
        margin-top: 20px;
        .job-card {
          padding: 44px 22px;
        }
      }
    }
  }
</style>
