export default [
  {
    name: '高级Java工程师',
    salary: '12-20K',
    workingYears: '5-10年',
    education: '专科',
    location: '长沙',
    res: [
      '参与后端系统开发中需求分析讨论/架构设计/联调等各个阶段的工作；',
      '负责后端系统功能代码实现, 单元测试及相关文档编写；',
      '与团队中其他成员合作，及时解决开发中遇到的问题；',
      '调研新知识新技术并进行合理输出及分享；',
      '对自己负责的工作内容的质量和稳定性保障, 并不断优化；',
      '负责系统性能分析, 稳定性保障及优化重构。',
    ],
    req: [
      '计算机相关专业，主Java技术栈；',
      '5年及以上后端开发工作经验，有电商/社交/教育等大型复杂业务系统经验的优先；',
      '熟练使用SpringBoot, Jpa, Mybatis常用框架, 并了解其原理；熟练使用MySQL/Redis, 了解互联网技术架构常用开源中间件技术；',
      '熟悉Spring相关生态链, 包括但不限于Springboot, SpringCloud, 有springcloud实际项目经验优先；',
      '熟悉微服务架构设计及高并发高可用架构常用解决方案及相关组件；',
      '熟悉应用压力测试/性能调优/线上问题定位相关手段及方法；',
      '熟悉Linux常用命令；',
      '对于代码的质量/健壮性/扩展性等方面有很高的追求, 思路清晰, 习惯编写单元测试更优, 责任感强；',
      '对技术有激情，能快速接受和掌握新技术，有较强的独立、主动的学习能力，良好的沟通表达能力和团队协作能力。',
    ],
    email: 'yanbodev@163.com',
  },
  {
    name: '中级前端工程师',
    salary: '8-16K',
    workingYears: '3-5年',
    education: '本科',
    location: '长沙',
    res: [
      '负责业务线项目整体技术管理和规划；',
      '根据UED规范和产品设计，按时按质的完成产品的前端开发工作；',
      '编写高质量代码，按照产品要求完成项目迭代，优化和提升用户体验；',
      '独立承担单个业务线的开发任务。',
    ],
    req: [
      '3~5年前端开发经验，熟练掌握HTML5/CSS3/JS(ES6)基本知识和使用；',
      '熟练使用Vue，熟悉NodeJS开发；',
      '熟练掌握Git基本使用；',
      '掌握前端工程化，拥有自动化流程开发能力者优先；',
      '掌握组件化能者优先；',
      '熟悉App混合开发者优先；',
      '熟悉Linux，有Linux下开发能力者优先；',
      '有完整微信小程序开发经验者优先。',
    ],
    email: 'leftice@163.com',
  },
  {
    name: '前端开发工程师',
    salary: '6-10K',
    workingYears: '1-3年',
    education: '本科',
    location: '长沙',
    res: [
      '负责业务线项目整体技术管理和规划；',
      '根据UED规范和产品设计，按时按质的完成产品的前端开发工作；',
      '编写高质量代码，按照产品要求完成项目迭代，优化和提升用户体验；',
      '	独立承担单个业务线的开发任务。',
    ],
    req: [
      '1~3年前端开发经验，熟练掌握HTML5/CSS3/JS(ES6)基本知识和使用；',
      '熟练使用Vue，熟悉NodeJS开发；',
      '熟练掌握Git基本使用；',
      '熟悉Linux，有Linux下开发能力者优先；',
      '有完整微信小程序开发经验者优先。',
    ],
    email: 'leftice@163.com',
  },
  {
    name: '移动端研发工程师',
    salary: '11-18K',
    workingYears: '3年以上',
    education: '不限',
    location: '长沙',
    res: ['负责移动端的新功能研发；', '负责移动端代码维护和性能优化；', '负责项目重难点的技术攻坚以及移动端新技术的预研。'],
    req: [
      '3年以上的移动端研发工作经验;', 
      '有扎实的Objective-C/Java语言基础，熟悉Swift/Kotlin，掌握iOS/Android研发工具和测试工具的使用;', 
      '熟悉iOS/Android应用研发框架、系统原理、内存管理、数据存储、网络通信等，了解常用性能调优手段;', 
      '熟悉面向对象化编程思想和设计模式，有一定的架构设计能力;', 
      '逻辑思维能力强，思路清晰，有创造性，有良好的英文资料阅读能力;',
      '工作踏实认真，有责任感，求知欲望强，有团队协作意识;',
      '具备跨平台研发经验者以及IM和音视频研发经验者优先。',
    ],
    email: 'yautou127107@qq.com',
  }
  // {
  //   name: 'iOS开发工程师',
  //   salary: '11-18K',
  //   workingYears: '2-3年',
  //   education: '本科',
  //   location: '长沙',
  //   res: ['iOS客户端的新功能开发；', 'iOS客户端代码维护和性能优化；', 'SDK的开发与维护。'],
  //   req: [
  //     '2年以上iOS平台相关研发经验；',
  //     '熟练掌握Swift语言或者有Objective-C两年及以上经验，熟悉iOS的内存管理机制和多线程开发，或具有良好的CC语言基础，了解内存和指针概念；',
  //     '对于优化程序的性能有一定经验，了解并使用面向接口开发模式，对http协议有一定了解，并可以熟练使用，熟悉XML和JSON数据结构；',
  //     '对于MVC框架或常见设计模式有自己的理解，并熟练使用，有良好的组织代码习惯；',
  //     '逻辑思维能力强，思路清晰，有创造性，有良好的英文资料阅读能力；',
  //     '工作踏实认真，有责任感，求知欲望强，有团队协作意识。',
  //   ],
  //   email: 'yautou127107@qq.com',
  // },
  // {
  //   name: '安卓开发工程师',
  //   salary: '11-18K',
  //   workingYears: '2-3年',
  //   education: '本科',
  //   location: '长沙',
  //   res: [
  //     '参与设计及开发幕童各类业务的android客户端；',
  //     '参与项目技术方案的制定，进行相关的设计或开发工作。',
  //   ],
  //   req: [
  //     '具有2年以上相关经验，精通Android手机平台，开发过成熟的android应用者优先；',
  //     '对OpenGLES2.0和移动端native开发有深入了解，能够编写着色器代码者优先；',
  //     '熟练使用Java和AndroidSDK；熟悉SQLite、JSON、TCPIP和HTTP协议；',
  //     '熟悉Java的多线程，线程与线程，进程与进程的通信机制；',
  //     '掌握常用应用架构，能独立开发高性能的Android应用，可独立开发各种自定义控件；',
  //     '熟悉Android平台下的视频，图像和音频功能开发；',
  //     '熟悉Android动画机制，事件传递和线程模型；',
  //     '对Android性能优化、内存优化有一定了解，技术思维活跃，学习能力强。',
  //   ],
  //   email: 'yautou127107@qq.com',
  // },
  // {
  //   name: '测试工程师',
  //   salary: '8-13K',
  //   workingYears: '1-3年',
  //   education: '本科',
  //   location: '长沙',
  //   res: [
  //     '根据项目安排进行app（ios、Android）、web、微信小程序的功能测试、接口测试、兼容性测试、数据埋点测试；',
  //     '理解项目需求，设计测试方案及计划，编写测试用例，执行测试，归纳和反馈问题并形成测试报告，推进问题及时解决使项目达到上线要求。',
  //   ],
  //   req: [
  //     '具备主观能动性、愿意学习进取，细心、细致、逻辑性强，能举一反三，善于发现问题、归纳和反馈问题、推进问题及时解决，能提出体验问题；',
  //     '拥有较好的沟通技巧及团队合作精神；',
  //     '具备丰富的app、web测试经验，能进行微信小程序测试；',
  //     '熟悉软件测试理论，掌握常用测试工具、测试流程及规范；',
  //     '能够很好的书写测试用例、测试报告。',
  //   ],
  //   email: '254089513@qq.com',
  // },
];
