export default [
  {
    name: '产品总监',
    salary: '30-40K',
    workingYears: '5-10年',
    education: '本科',
    location: '北京',
    res: [
      '负责公司所有产品线产品管理和团队管理工作，对业务领域达成商业目标负责；',
      '基于对所有产品的市场分析和行业洞察，定义产品的中长期目标，分解核心指标，规划产品路径；',
      '规划及确认产品新需求及新功能方向；根据产品计划，全面监控产品创意、立项、规划、需求、分析结果，确保其符合公司产品发展方向；验收产品，对其上线输出产品负责；',
      '关注核心运营数据，根据行业动态及产品定位，分析产品竞争力和问题，保障产品经营效果；',
      '能够通过授权、激励等管理手段充分发挥团队成员优势，促进团队合作，解决人员冲突，带领团队成员完成工作目标。激发团队成员的动力，营造良好团队氛围。',
    ],
    req: [
      '5年以上产品经理经验，有B端SaaS产品和C端产品背景；',
      '精通Axure、Visio、Mindmanager等工作软件；',
      '具备产品从0-1的经验，包括市场分析、需求分析、产品功能设计、业务流程设计、界面设计、用户研究和可用性测试等；',
      '优秀的商业洞察力、逻辑思维能力、沟通协调能力，有良好的团队协作意识。能承受工作压力；',
      '具备完整的产品理念和方法论，有成功产品经验者优先；',
      '1年以上10人以上产品团队管理工作经验。',
    ],
    email: 'lhoo@vip.qq.com',
  },
  {
    name: '高级产品经理',
    salary: '11-18K',
    workingYears: '1-3年',
    education: '本科',
    location: '长沙',
    res: [
      '负责幕童相关产品的产品设计和规划；',
      '负责产品需求的收集、整理、撰写，进行用户需求分析，了解竞品动态与行业发展；',
      '以用户为中心，从用户角度出发驱动产品，技术和其它团队；',
      '数据导向，对社区核心指标进行监控和分析，以此指导产品迭代。',
    ],
    req: [
      '1-3年产品工作经验；',
      '优秀的逻辑思维、数据分析能力，良好的沟通能力，能够独立思考；',
      '熟悉互联网产品实现流程，有效推动各方资源达成目标；',
      '具备良好的用户体验意识，换位思考，同时具备创新精神；',
      '了解产品的基本思路及基础方法论，能够基于数据提出产品优化策略。',
    ],
    email: '298985499@qq.com',
  },

  {
    name: '产品经理（社区方向）',
    salary: '10-15K',
    workingYears: '1-3年',
    education: '本科',
    location: '长沙',
    res: [
      '负责幕童社区类产品规划、设计以及持续改进优化，明确社区调性和基于调性的内容生产消费行为激励；',
      '负责产品需求的收集、整理、撰写，进行用户需求分析，了解竞品动态与行业发展；',
      '以用户为中心，从用户角度出发驱动产品，技术和其它团队；',
      '数据导向，对社区核心指标进行监控和分析，以此指导产品迭代。',
    ],
    req: [
      '1-3年产品工作经验；',
      '优秀的逻辑思维、数据分析能力，良好的沟通能力，能够独立思考；',
      '熟悉互联网产品实现流程，有效推动各方资源达成目标；',
      '具备良好的用户体验意识，换位思考，同时具备创新精神；',
      '了解社区运营的基本思路，能够基于数据提出产品优化策略。',
    ],
    email: '298985499@qq.com',
  },

  {
    name: '产品经理（课堂方向）',
    salary: '10-15K',
    workingYears: '1-3年',
    education: '本科',
    location: '长沙',
    res: [
      '负责幕童课堂的产品需求收集与分析，梳理并落地教学运营部门的业务需求；',
      '负责功能规划和设计，包括产品界面、流程、功能等；',
      '负责产品研发过程中的项目管理，把握里程碑节点，协调设计、研发、测试等相关部门资源，推动产品研发进度。',
    ],
    req: [
      '1-3年以上互联网产品工作经验；',
      '擅长洞察用户需求，打破常规，能产出新颖的解决方案并落地产品；',
      '对视觉和交互细节敏感，对用户体验执着，数据化思考；',
      '主动性强，有优秀的理解和沟通能力、清晰的逻辑思维框架，善于学习，喜欢发现问题，并能强有力的解决问题；',
      '具有良好的团队合作意识，执行力、沟通协作能力、项目管理能力,并拥有强烈的责任心,结果导向；',
      '加分项：具有教育类产品、项目经验优先考虑。',
    ],
    email: '298985499@qq.com',
  },
];
