export default [
  // {
  //   name: '内容运营',
  //   salary: '6-12K',
  //   workingYears: '1-3年',
  //   education: '专科',
  //   location: '长沙',
  //   res: [
  //     '图文、视频等优质内容搬运、排版、二次剪辑、上传等操作；',
  //     '挖掘行业内的最新热点，提炼、整理、包装有价值的专题内容；',
  //     '创建创作者和机构类马甲号，进行马甲号分类管，模拟用户行为进行日常操作（发布内容），营造社区氛围。',
  //   ],
  //   req: [
  //     '两年以上互联网内容运营经验，有影视制作行业从业经验优先考虑；',
  //     '了解运营后台的常规操作；',
  //     '扎实的文案功底，具备独立策划优质内容的能力；',
  //     '有良好的沟通交流能力，具有创新意识。',
  //   ],
  //   email: '493354593@qq.com',
  // },

  // {
  //   name: '短视频运营',
  //   salary: '7-12K',
  //   workingYears: '1-3年',
  //   education: '专科',
  //   location: '长沙',
  //   res: [
  //     '针对B站/抖音/微信视频号/快手等视频平台特性，负责内容策划和日常运营；',
  //     '视频后期剪辑处理，会PS及PR等软件；',
  //     '视频平台日常数据监控、统计及分析；',
  //     '擅长捕捉热点视频，进行二次创意创作。',
  //   ],
  //   req: [
  //     '具备扎实的文案策划能力；',
  //     '对热点事件触觉敏感，有选题能力；',
  //     '有成功运营过视频平台经验者优先。',
  //   ],
  //   email: '493354593@qq.com',
  // },

  {
    name: '产品运营',
    salary: '7-13K',
    workingYears: '1-3年',
    education: '专科',
    location: '长沙',
    res: [
      '在运营团队中，与产品经理、品牌推广一起为拉新指标负责；',
      '规划围绕积幕、幕童有课等产品的用户新增方案、用户转化路径；',
      '基于不同的用户分层设计运营方案，策划活动，提升转化率，并根据效果数据进行快速迭代；',
      '规划用户激励体系，积分体系、勋章体系、会员体系等。',
    ],
    req: [
      '从事增长、商业化产品运营、用户运营工作2年以上；',
      '非应届岗位，21届勿扰；',
      '有新用户转化经验者优先；',
      '对数据敏感，具备优秀的数据分析能力；',
      '思维活跃、逻辑清晰、善于思考和创新、有较强的ownership和执行力。',
    ],
    email: '493354593@qq.com',
  },
];
